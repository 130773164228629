.graphical-question {
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
}

.graphical-option {
  width: 100%;
  height: 100px;
  margin-bottom: 40px;
}

.passage-outer {
  height: 200px;
  overflow-y: scroll;
}

.users-table,
.users-table th,
.users-table td {
  font-size: 12px;
  padding: 4px;
}
